const QUERY_PARAMS_RELIABLE_ROUTES = ['booking', 'search'];

export default defineNuxtRouteMiddleware((to, from) => {
  if (!from.fullPath || !to.fullPath) return;

  const shouldCleanQueryParams = QUERY_PARAMS_RELIABLE_ROUTES.some(
    route => from.fullPath.includes(route) && !to.fullPath.includes(route),
  );

  if (shouldCleanQueryParams) {
    to.query = {};
  }

  return navigateTo(to);
});
